import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (error?.message?.match(/Loading chunk \d+ failed./i)) event.fingerprint = ["chunk-loading-error"];
    if (error?.message?.match(/The quota has been exceeded/i)) event.fingerprint = ["indexeddb-quota"];
    if (error?.message?.match(/The operation is insecure/i)) event.fingerprint = ["insecure-operation"];
    if (error?.message?.match(/Maximum worker number has been reached/i)) event.fingerprint = ["worker-quota"];
    if (error?.message?.match(/We couldn't load "\/page-data/i)) event.fingerprint = ["graphql-load-failure"];
    if (error?.message?.match(/page resources for \S+ not found/i)) event.fingerprint = ["render-error"];

    return event;
  },
  dsn: process.env.GATSBY_SENTRY_DSN,
  sampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.2,
  ignoreErrors: [
    /AbortController/,
    /window\.bannerNight/,
  ],
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});
