import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import * as Comlink from "comlink"
import { firebaseConfig } from "../../firebaseConfig";

import {
  debounce,
  getUser,
  isBrowser,
  isPremiumSet,
  isPremium,
  isLoggedIn,
  setUser,
  setPremium
} from "../services/auth";

const AuthContext = React.createContext();

const Authenticate = ({ children, location, rootContext }) => {
  const [debounced, setDebounced] = useState();

  useEffect(() => {
    if (!isBrowser()) return;
    const worker = new Worker("/workers/main.js");
    const obj = Comlink.wrap(worker);
    const referrer = document.referrer;

    (async () => {
      const deb = await debounce();
      setDebounced(deb);
    })()

    if (debounced || isPremium()) return;

    if (referrer === '') {
      (async () => {
        await obj.storeReferrer({ firebaseConfig, referrer });
      })();
    }

    if (location.search !== '') {
      const query = queryString.parse(location.search);
      (async () => {
        await obj.storeCampaign({ firebaseConfig, query });
      })();
    }

    (async () => {
      if (isLoggedIn()) {
        const uid = getUser();
        await obj.saveCampaign({ firebaseConfig, uid });
      }
    })();

    if (!isLoggedIn()) {
      (async () => {
        await obj.authenticate(firebaseConfig);
        const user = await obj.user
        setUser(user);
        setPremium(user.premium);
      })();
    } else if (isLoggedIn() && !isPremiumSet()) {
      (async () => {
        await obj.authenticate(firebaseConfig);
        const user = await obj.user
        setPremium(user.premium);
      })();
    }
  },[debounced, location])

  return (
    <AuthContext.Provider value={ rootContext }>
      {children}
    </AuthContext.Provider>
  )
}

export { Authenticate, AuthContext }

