import React from "react";
import * as Sentry from "@sentry/browser";
import {
  getGeo,
  getUser,
  getLoginDetail,
  getPremium,
  isBrowser,
  isPremium,
} from "../services/auth";

const SentryContext = React.createContext();

const SentrySetup = ({ children, rootContext }) => {
  Sentry.configureScope(async (scope) => {
    if (!isBrowser()) return;
    const { data, quota } = await getGeo();
    const country = data.country || `not available`;
    const city = data.city || `not available`;
    const id = getUser() || `not logged in`;
    const expires = new Date(getPremium() || `1970-01-01`);
    const premium = isPremium();
    const username = getLoginDetail() || `not logged in`;

    scope.setUser({
      city,
      country,
      expires,
      id,
      premium,
      quota,
      username,
    });
  });

  return (
    <SentryContext.Provider value={ rootContext }>
      {children}
    </SentryContext.Provider>
  )
}

export { SentryContext, SentrySetup }

