import * as Comlink from "comlink"
import * as Sentry from "@sentry/browser";

const TTL = 5000
const DAY = 86399993
const isBrowser = () => typeof window !== "undefined"


const clearPremium = () => {
  window.localStorage.removeItem("premium");
  window.localStorage.removeItem("debounce");
}

const login = ({ method }) => {
  if(!isBrowser()) return;
  if(typeof window.gtag !== 'function') return;
  window.gtag("event", "login", {
    method
  });
}

const getGeo = async () => {
  if (!isBrowser()) return;
  const worker = new Worker("/workers/main.js");
  const obj = Comlink.wrap(worker);
  await obj.getGeo();
  const data = await obj.data;
  const supported = await obj.supported;
  const error = await obj.error;
  if (error) Sentry.captureException(error);

  let quota;
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    const estimate = await navigator.storage.estimate();
    quota = parseInt(estimate.usage / estimate.quota * 100);
  } else {
    quota = -1;
  }

  return { data, quota, supported };
}

const debounce = async () => {
  if (!isBrowser()) return;
  const worker = new Worker("/workers/main.js");
  const obj = Comlink.wrap(worker);
  await obj.debouncer();
  const debounced = await obj.debounce;
  return debounced
}

const getEmail = () => isBrowser() && getWithExpiry("userEmail");
const getLoginDetail = () => {
  if (!isBrowser()) return
  const user = getWithExpiry("user");
  return (user?.phoneNumber || user?.displayName || user?.email)
}
const getProvider = () => {
  if (!isBrowser()) return
  const user = getWithExpiry("user");
  return user?.providerData[0]?.providerId
}
const getPremium = () => getWithExpiry("premium")
const getUser = () => getWithExpiry("userId");

const isPremiumSet = () => !!(getWithExpiry("premium"))
const isLoggedIn = () => !!(getUser())
const isPremium = () => (getPremium() >= new Date().getTime());

const logout = async (firebase, mounted) => {
  if (!mounted.current) return;
  await firebase.auth().signOut();
  window.localStorage.removeItem("user");
  window.localStorage.removeItem("userId");
  window.localStorage.removeItem("userEmail");
  window.localStorage.removeItem("premium");
  window.localStorage.removeItem("debounce");
  return window.location.reload();
}

const removeKey = (key) => isBrowser() && window.localStorage.removeItem(key);

const silentLogout = async ({ firebase, mounted }) => {
  if (!isBrowser()) return;
  if (!mounted.current) return;
  await firebase.auth().signOut();
  window.localStorage.removeItem("user");
  window.localStorage.removeItem("userId");
  window.localStorage.removeItem("userEmail");
  window.localStorage.removeItem("premium");
  window.localStorage.removeItem("debounce");
}

const setPremium = premium => {
  if (typeof premium !== 'number') return;
  const ttlPremium = premium - new Date().getTime();
  if (!ttlPremium >= TTL) return;
  setWithExpiry("premium", premium, ttlPremium);
}
const setUser = user => {
  if (!isBrowser()) return;
  setWithExpiry("user", user, DAY);
  setWithExpiry("userId", user.uid, DAY);
  setWithExpiry("userEmail", user.email, DAY);
}

const setWithExpiry = (key, value, ttl) => {
  if (!isBrowser()) return;
  const now = new Date().getTime();
  const item = {
    value: value,
    expiry: now + (ttl || TTL),
  }
  window.localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = (key) => {
  if (!isBrowser()) return;
  const itemStr = window.localStorage.getItem(key);
  if (!itemStr) return null
  const item = JSON.parse(itemStr)
  const now = new Date().getTime();

  if (now > item.expiry) {
    window.localStorage.removeItem(key)
    return null
  }

  return item.value
}


export {
  clearPremium,
  debounce,
  getEmail,
  getGeo,
  getLoginDetail,
  getProvider,
  getPremium,
  getUser,
  getWithExpiry,
  isBrowser,
  isLoggedIn,
  isPremium,
  isPremiumSet,
  login,
  logout,
  removeKey,
  setPremium,
  setUser,
  setWithExpiry,
  silentLogout,
}
