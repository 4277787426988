import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { login, setPremium, setUser } from "./src/services/auth";

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
}

let auth,
  currentUser,
  firestore,
  functions,
  instance,
  timestamp,
  uiConfig;

const getFirebase = () => {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    try {
      instance = firebase.initializeApp(firebaseConfig);
    } catch (e) {
      if (e.message.match(/firebase app.+already exists/i))
        window.location.reload()
      else
        throw (e)
    }

    auth = firebase.auth();
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    firestore = firebase.firestore();
    functions = firebase.functions();
    timestamp = firebase.firestore.FieldValue.serverTimestamp();

    firebase.auth().onAuthStateChanged((user) => { if (user) currentUser = user });

    uiConfig = {
      signInSuccessUrl: `/`,
      signInFlow: `popup`,
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
        },
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        },
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: async function(authResult) {
          const user = authResult.user;
          const idTokenResult = await user.getIdTokenResult();
          const premium = idTokenResult && idTokenResult.claims && idTokenResult.claims.premium;
          setUser(user);
          setPremium(premium);
          login({ method: user.providerData[0].providerId } );
          return true;
        },
      }
    };
    return instance;
  }
  return null;
}

export { auth, firebaseConfig, currentUser, firestore, functions, getFirebase as default, timestamp, uiConfig };

