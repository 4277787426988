import React from "react"
import { SentrySetup } from "./src/hooks/sentry-setup"
import { Authenticate } from "./src/hooks/authenticate"

const wrapPageElement = ({ element, props }) => (
  <Authenticate {...props}>
    <SentrySetup {...props}>
      {element}
    </SentrySetup>
  </Authenticate>
)

export default wrapPageElement
